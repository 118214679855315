import React from 'react'

export const DownloadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 20.0002C5.1 20.0002 4.75 19.8502 4.45 19.5502C4.15 19.2502 4 18.9002 4 18.5002V14.9252H5.5V18.5002H18.5V14.9252H20V18.5002C20 18.9002 19.85 19.2502 19.55 19.5502C19.25 19.8502 18.9 20.0002 18.5 20.0002H5.5ZM12 16.1752L7.175 11.3502L8.25 10.2752L11.25 13.2752V4.00024H12.75V13.2752L15.75 10.2752L16.825 11.3502L12 16.1752Z"
      fill="var(--color-support-links)"
    />
  </svg>
)
